@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&display=swap");

.about {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #141414;
  padding-top: 50px;
  padding-bottom: 30px;
  .container-about {
    width: 80%;
    @media (max-width: 768px) {
      width: 90%;
    }
    .header-about {
      text-align: center;
      @media (max-width: 768px) {
        text-align: left;
      }
      .judul-about {
        font-family: "DM Serif Display";
        color: white;
        font-size: 42px;
        letter-spacing: 2px;
        @media (max-width: 768px) {
          font-size: 30px;
        }
      }
      .desk-about {
        color: rgb(200, 200, 200);
        @media (max-width: 768px) {
          font-size: 15px;
        }
      }
    }
    .list-benefit {
      @media (max-width: 768px) {
        width: 100%;
      }
      .list {
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 768px) {
          flex-direction: column;
          width: 100%;
        }

        ul {
          padding-inline-start: 0px;
          background-color: aliceblue;
          display: flex;
          padding-left: 30px;
          padding-right: 30px;
          border-radius: 10px;
          @media (max-width: 768px) {
            flex-direction: column;
            padding: 0px;
            width: 100%;
          }
        }
        ul li {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-size: 24px;
          list-style-type: none;
          padding: 20px 50px;
          text-align: center;
          @media (max-width: 768px) {
            padding: 15px 20px 10px 10px;
            flex-direction: row;
            text-align: left;
            justify-content: left;
          }
        }
      }
    }
  }
}
