.a {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 50px;
  @media (max-width: 768px) {
    margin-top: 10px;
  }
  .judul {
    font-weight: 600;
    font-size: 30px;
    color: #273444;
    @media (max-width: 768px) {
      text-align: center;
    }
  }
}

.container-promonew1 {
  margin-top: 30px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end; /* Added justify-content */
  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 0;
  }
  .containergambar {
    order: 1;
    width: 50%;
    text-align: right;
    @media (max-width: 768px) {
      margin-top: 25px;
      order: 1;
      width: 100%;
      text-align: center;
    }

    .promonew1-gambar {
      width: 500px;
      height: 650px;
      align-self: flex-end;
      box-shadow: 0 0 10px 0;
      border-radius: 5px;
      @media (max-width: 768px) {
        width: 90%;
        height: 100%;
      }
    }
  }

  .containercontent {
    order: 2;
    width: 50%;
    margin-left: 100px;
    text-align: left;
    justify-content: center;
    display: flex;
    flex-direction: column;
    @media (max-width: 768px) {
      margin-left: 25px;
      width: 90%;
      order: 2;
      margin-top: 15px;
    }
  }
  .disclaimer {
    margin-top: 25px;
    font-size: 13px;
  }
  .point-promonew1 {
    flex-direction: row;
    font-size: 30px;
    @media (max-width: 768px) {
      font-size: 25px;
      width: 100%;
    }
    .promonew1-poin {
      margin-top: 0px;
      color: #273444;
      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }
  .buttonNew3 {
    color: black;
    margin-top: 30px;
    background-color: transparent;
    width: 50%;
    padding: 15px 30px;
    border: 1px solid black; /* Add this line for black border */
    border-color: black;
    border-radius: 25px;
    font-size: 16px;
    font-weight: light;
    cursor: pointer;
    display: inline-block;
    transition: background-color 0.3s ease, color 0.3s ease;
    @media (max-width: 768px) {
      width: 70%;
    }
    &:hover {
      background-color: black;
      color: white;
    }
  }
}
