@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&display=swap");

.devider {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(../../asset/utama/background-devider.webp);
  background-size: cover;
  height: 80vh;
  @media (max-width: 768px) {
    height: 100vh;
  }
  .container-devider {
    width: 80%;
    display: flex;
    @media (max-width: 768px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 95%;
    }
    .logocitraland {
      width: 100%;
      align-self: center;
      text-align: center;
      img {
        width: 50%;
        @media (max-width: 768px) {
          width: 80%;
        }
      }
    }
    .header-devider {
      width: 100%;
      background-color: white;
      padding: 50px;
      border-radius: 10px;
      box-shadow: 0 0 10px 0;
      @media (max-width: 768px) {
        padding-left: 10px;
        padding-right: 0px;
        width: 95%;
      }
      .judul-devider {
        font-family: "DM Serif Display";
        font-size: 36px;
        letter-spacing: 2px;
      }
    }
  }
}
