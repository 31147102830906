@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.container1 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 70px;
  flex-direction: column;
  width: 100%;
  .header-newlaunch {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: #3c4253;
    margin-top: 2%;
    margin-bottom: 5%;
    text-align: center;
    font-size: 36px;
    color: white;
  }
  .judul-newlaunch1 {
    font-family: "Poppins";
    color: #273444;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 50px;
    @media (max-width: 768px) {
      width: 100%;
      text-align: center;
      margin-bottom: 20px;
    }
    .judul1 {
      font-weight: 400;
      font-size: 22px;
    }
    .judul2 {
      font-weight: 600;
      font-size: 50px;
      letter-spacing: 3px;
      @media (max-width: 768px) {
        font-size: 28px;
      }
    }
    .judul3 {
      font-weight: 600;
      font-size: 41px;
      margin-top: -15px;
      letter-spacing: 0.5px;
      @media (max-width: 768px) {
        font-size: 20px;
        width: 100%;
        letter-spacing: 0px;
        margin-top: -5px;
      }
    }
  }
}
