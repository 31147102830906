.produk {
  width: 100%;
  .p {
    width: 100%;
    background-color: #3c4253;
    display: flex;
    justify-content: center;
    align-items: center;
    .container-produk {
      width: 80%;
      .header-produk {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 768px) {
          flex-direction: column;
          padding-top: 20px;
        }
        .judul-produk {
          width: 100%;
          font-size: 36px;
          color: white;
          @media (max-width: 768px) {
            text-align: center;
          }
        }
        .slider-logo {
          width: 20%;
          @media (max-width: 768px) {
            width: 80%;
          }
          .slick-dots {
            bottom: 15px;
            li {
              width: 10px;
            }
          }
          .slick-next {
            right: 25px;
          }
          .slick-next::before,
          .slick-prev::before {
            font-family: "slick";
            font-size: 30px;
          }
          .slick-prev {
            z-index: 1;
            left: 25px;
          }
          .kartugambar {
            width: 10%;
          }
        }
      }
    }
  }
}
