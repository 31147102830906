.hero {
  width: 100%;
  .container-hero {
    background-image: url("../../asset/utama/banner-desktop.webp"); /* Replace with your desktop image URL */
    background-size: cover;
    background-position: center;
    height: 100vh;
    background-size: cover;
    //border-radius: 0px 0px 0px 100px;
    //box-shadow: inset 0px -5px 10px #d5d5d5;
    @media (max-width: 768px) {
      /* Adjust breakpoint as needed */
      background-image: url("../../asset/utama/banner-mobile.webp"); /* Replace with your mobile image URL */
      height: 100vh;
      background-repeat: no-repeat;
      // border-radius: 0px 0px 0px 30px;
    }
  }
}
