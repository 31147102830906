.district9 {
  margin-top: 5%;
  width: 100%;
  .container-district9 {
    width: 100%;
    .header-district9 {
      width: 100%;
      text-align: center;
      .logo {
        width: 100%;
        img {
          border-radius: 10px;
          background-color: #3c4253;
          box-shadow: inset 0 0 10px 0;
          width: 30%;
          @media (max-width: 768px) {
            width: 90%;
          }
        }
      }
    }
    .cardhome-container {
      margin-top: 25px;
      width: 100%;
      display: grid;
      grid-template-columns: auto auto auto;
      justify-content: center;
      @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
}
