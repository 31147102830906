.container-card {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
    overflow: hidden;
  }
  .kartu {
    width: 380px;
    height: 800px;
    margin-left: 15px;
    margin-top: 10px;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 0 10px 0;
    hr {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 15px;
      width: 90%;
    }
    @media (max-width: 768px) {
      width: 350px;
      margin-bottom: 20px;
      margin-left: 0px;
      height: 740px;
    }
    .kartugambar {
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
    }
    .kartugambar1 {
      width: 100%;
    }
    .slick-next::before,
    .slick-prev::before {
      font-family: "slick";
      font-size: 30px;
    }
    .slick-list {
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
    }

    .slick-dots {
      position: absolute; /* Make dots absolute for positioning within slides */
      bottom: 10px; /* Adjust vertical positioning */
      left: 50%; /* Center dots horizontally */
      transform: translateX(-50%); /* Center dots precisely */
      z-index: 1; /* Ensure dots are above image content */
    }
    .slick-next {
      right: 25px;
    }
    .slick-prev {
      z-index: 1;
      left: 25px;
    }
    .slick-dots {
      li {
        margin: 0;
      }
    }

    .container-title-card {
      width: 100%;
      margin-top: 10px;
      display: flex;

      .titlecard {
        width: 50%;
        padding: 10px;
        margin: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .judul-rumah {
          color: #273444;
          font-family: "Poppins";
          font-size: 30px;
          font-weight: 600;
        }
      }
      .cicilan {
        width: 50%;
        background-color: #273444;
        padding: 10px;
        border-radius: 10px 0px 0 10px;
        color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .angka {
          font-family: "Poppins";
          font-size: 48px;
        }
        .ket {
          font-family: "Poppins";
          font-size: 17px;
        }
      }
    }
    .gridspek {
      padding-left: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 5px;
        padding-right: 20px;
        @media (max-width: 768px) {
          padding-right: 20px;
          font-size: 12px;
        }
      }
    }
    .containerwhatsapp {
      display: flex;
      justify-content: center;
      padding-top: 25px;
      .whatsapp {
        display: inline-block;
        padding: 10px 100px;
        cursor: pointer;
        border: none;
        border-radius: 5px;
        background-color: #273444; /* WhatsApp green */
        color: white;
        font-size: 16px;
      }
    }
  }
}
