.lokasi {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
  margin-bottom: 10%;
  .container-lokasi {
    width: 80%;
    @media (max-width: 768px) {
      width: 98%;
    }

    .headerlokasi {
      width: 100%;

      .judul-lokasi {
        width: 100%;
        text-align: center;
        font-size: 50px;
        font-family: "Playfair Display";
        color: #273444;
        font-weight: 800;

        @media (max-width: 768px) {
          font-size: 28px;
        }
      }

      .image-lokasi {
        margin-top: 5%;
        width: 100%;
        display: flex;
        flex-direction: column;
        .gambar {
          width: 100%;
          height: auto; /* Consider adjusting height based on image dimensions */
          box-shadow: 0 0 10px 0 rgb(80, 80, 80);
          border-radius: 10px;
        }

        .button {
          box-shadow: 0 0px 5px 0 rgb(69, 69, 69);
          padding: 15px 30px;
          font-size: 14px;
          background: linear-gradient(
            167deg,
            rgba(9, 9, 121, 1) 10%,
            rgba(0, 212, 255, 1) 90%
          );
          color: white;
          border-radius: 5px;
          margin-top: -10px;
          cursor: pointer;
          border-width: 0px;
          &:hover {
            background: linear-gradient(
              167deg,
              rgba(0, 212, 255, 1) 10%,
              rgba(9, 9, 121, 1) 90%
            );
            transition: 0.5s;
          }
          @media (max-width: 768px) {
            margin-top: 0px;
          }
        }
      }
    }
  }
}
